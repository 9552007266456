<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="name" :label="$t('name')">
                <ElInput
                  v-model="form.name"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="quantity" :label="$t('quantity')">
                <ElInputNumber v-model="form.quantity" type="number" min="0" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="tax_percent" :label="$t('tax_percent')">
                <ElInputNumber
                  v-model="form.tax_percent"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="tax_amount" :label="$t('tax_amount')">
                <ElInputNumber
                  v-model="form.tax_amount"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="discount_percent"
                :label="$t('discount_percent')"
              >
                <ElInputNumber
                  v-model="form.discount_percent"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="discount_amount" :label="$t('discount_amount')">
                <ElInputNumber
                  v-model="form.discount_amount"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="subtotal" :label="$t('subtotal')">
                <ElInputNumber
                  v-model="form.subtotal"
                  type="number"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="total" :label="$t('total')">
                <ElInputNumber
                  v-model="form.total"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="cost" :label="$t('cost')">
                <ElInputNumber
                  v-model="form.cost"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="price" :label="$t('price')">
                <ElInputNumber
                  v-model="form.price"
                  type="number"
                  step="0.01"
                  min="0"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="product_uuid"
                :label="$t('product')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getProductKey()"
                  v-loading="getProductKey() === 0"
                  v-model="form.product_uuid"
                  :remote-method="searchProducts"
                  :placeholder="$t('select_a_product')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchProducts()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewProductDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewProductDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_product') }}
        </span>
      </template>
      <QuickCreateProductDialog
        @create=";(showNewProductDialog = false), createProduct($event)"
        @cancel="showNewProductDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_INVOICE_ITEM from '@/graphql/invoice-items/mutation/createInvoiceItem.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  invoice: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  productless: '',
  name: '',
  quantity: 0,
  subtotal: 0,
  tax_percent: 0,
  tax_amount: 0,
  discount_percent: 0,
  discount_amount: 0,
  total: 0,
  cost: 0,
  price: 0,
  product_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewProductDialog = ref(false)

const rules = {
  name: { required }
}

const v$ = useVuelidate(rules, form)

onMounted(() => {
  initialProducts()
})

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: 'blur'
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid || !props.invoice?.uuid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_INVOICE_ITEM, {
    name: form.name,
    quantity: form.quantity ? parseInt(form.quantity) : 0,
    invoice_uuid: props.invoice.uuid,
    subtotal: form.subtotal ? parseInt(form.subtotal * 100) : 0,
    tax_percent: form.tax_percent ? parseInt(form.tax_percent * 100) : 0,
    tax_amount: form.tax_amount ? parseInt(form.tax_amount * 100) : 0,
    discount_percent: form.discount_percent
      ? parseInt(form.discount_percent * 100)
      : 0,
    discount_amount: form.discount_amount
      ? parseInt(form.discount_amount * 100)
      : 0,
    total: form.total ? parseInt(form.total * 100) : 0,
    cost: form.cost ? parseInt(form.cost * 100) : 0,
    price: form.price ? parseInt(form.price * 100) : 0,
    product_uuid: form.product_uuid,
    productless: form.product_uuid ? false : true
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createInvoiteItem ?? null)
    loading.value = false
  }
}

const createProduct = (event) => {
  if (event?.uuid) {
    form.product_uuid = event.uuid
    form.product = event
    searchProducts(event.name, form.product, null, true)
  }
}
</script>
